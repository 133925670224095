import React, { useState }  from "react"
import Layout from '../components/layout';
import * as styles from '../styles/styleconstants';
import axios from "axios";

const spacerCorrect = {
  marginTop: 20,
}

const MyForm = () => {
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/f971a8a4-95fe-4f19-8068-54227a782be4",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (
    
    <Layout>
      <main style={styles.pageStyles}>
      <title>Contact</title>
      <h1 style = {styles.headingStyles}>Contact</h1>
    <div>
         <div className="col-md-8 mt-5">
            <form onSubmit={handleOnSubmit}>
            <div className="form-group">
                <label for="InputName">Name: </label>
                <input type="text" name="name" className="form-control" id="InputName" required="required"/>
            </div>
            <div className="form-group">
                <label for="InputEmail" required="required">Email address: </label>
                <input type="email" name="email" className="form-control" id="InputEmail" aria-describedby="emailHelp" required="required"/>
            </div> 
            <div className="form-group">
                <label for="InputSubject" >Subject: </label>
                <input type="text" name="subject" className="form-control" id="InputSubject" required="required"/>
            </div>
            <div className="form-group">
                <label for="InputMessage">Message: </label>
                <textarea type="text" rows="3" name="message" className="form-control" required="required"/>
            </div>

            <button type="submit" className="btn btn-primary btn-lg"  disabled={serverState.submitting} style = {styles.buttonStyle}>
                Submit
            </button>
            {serverState.status && (
                <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
                </p>
            )}
            </form>
            <p style = {styles.bottomSpacer}></p>
        </div>
      </div>  
      </main>
  </Layout>
     
    );
  };
  
  export default MyForm;